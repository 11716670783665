import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import style from './style.js'
import { Link as LinkHelper, RichText } from 'lib/prismic'

function HtmlComponent(props) {
  const { primary, classes } = props

  return (
    <div>
      <Container>
        <div
          dangerouslySetInnerHTML={{ __html: RichText.asText(primary.html) }}
        ></div>
      </Container>
    </div>
  )
}

HtmlComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(HtmlComponent)
