const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    backgroundColor: '#fff',
  },
  description: {
    maxWidth: 700,
    margin: '0px auto',
  },
  content: {
    fontSize: 22,
    fontWeight: 400,
  },
  projects: {
    marginTop: 20,
  },
  buttons: {
    marginTop: 30,
  },
  projectLink: {
    textDecoration: 'none',
  },
})

export default styles
