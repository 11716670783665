import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style.js'
import { Link as LinkHelper, RichText } from 'lib/prismic'
import { Grid, Container } from '@material-ui/core'

const Text = function (props) {
  const { classes, primary } = props

  return (
    <div className={classes.outer}>
      <Container className={classes.body}>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          spacing={10}
        >
          <Grid item xs={12} md={10}>
            {RichText.render(primary.rich_text)}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(Text)
