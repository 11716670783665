const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    textAlign: 'center',
    backgroundColor: '#F3F3F3',
  },
  image: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    borderRadius: '50%',
    border: '3px solid' + theme.palette.primary[500],
    margin: '0px auto',
  },
  slider: {
    marginTop: 40,
    marginBottom: 50,
    '@global': {
      'ul.slick-dots': {
        bottom: -50,
      },
    },
  },
  name: {
    color: theme.palette.primary[500],
    fontWeight: '600',
    marginTop: 20,
  },
  gridItem: {
    position: 'relative',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  small: {
    fontSize: 21,
  },
  buttons: {
    marginTop: 5,
  },
  container: {
    maxWidth: 900,
    margin: '0px auto',
  },

  description: {
    maxWidth: 700,
    margin: '0px auto',
  },
  infiniteScroll: {
    margin: -20,
    padding: 20,
  },
  item: {
    marginTop: 10,
  },
})

export default styles
