const styles = (theme) => ({
  outer: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    color: 'black',
    padding: '80px 0px',
  },
  title: {
    color: 'black',
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 20,
  },
  content: {
    fontSize: 22,
    fontWeight: 400,
  },
  titleOuter: {},
})

export default styles
