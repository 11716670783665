const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    textAlign: 'center',
    backgroundColor: '#F3F3F3',
  },
  articles: {
    marginTop: 20,
    textAlign: 'center',
  },

  description: {
    maxWidth: 700,
    margin: '0px auto',
  },
  infiniteScroll: {
    margin: -20,
    padding: 20,
  },
})

export default styles
