const styles = (theme) => ({
  heading: {
    textAlign: 'center',
    margin: '0px auto',
    color: '#000',
  },
  title: {
    margin: 0,
  },
  description: {
    maxWidth: 800,
    margin: '0px auto',
    paddingTop: 10,
    fontSize: '1.3rem',
  },
})

export default styles
