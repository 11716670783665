const styles = (theme) => ({
  outer: {
    position: 'relative',
    width: '100%',
    height: '500px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
  },
  fullHeight: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(16,39,65,0.3)',
  },
  outlinedButtonInnerText: {
    color: 'white',
  },
  sub_title: {
    fontStyle: 'italic',
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 30,
  },
  titleOuter: {
    maxWidth: 600,
    textAlign: 'center',
    color: '#fff',
    '@global': {
      '.title-underline': {
        borderBottom: '3px solid ' + theme.palette.primary[500],
      },
      h1: {
        fontSize: 40,
      },
    },
  },
})

export default styles
