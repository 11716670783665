import React from 'react'
import PropTypes, { any } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'

function Hero(props) {
  const { classes, fields, primary } = props
  const bgi = { backgroundImage: `url(${primary.background_image.url})` }
  return (
    <div style={bgi} className={classes.outer}>
      <Grid
        container
        className={classes.fullHeight}
        alignItems='center'
        justify='center'
      >
        <Grid item className={classes.titleOuter}>
          {RichText.render(primary.title)}
          <p className={classes.sub_title}>
            {RichText.asText(primary.sub_title)}
          </p>
          <Grid
            container
            justify='center'
            alignItems='center'
            spacing={5}
          ></Grid>
        </Grid>
      </Grid>
    </div>
  )
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Hero)
