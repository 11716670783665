import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment HomeArticles on PageBodyLast_articles_preview{
    fields{
      last_articles {
        _linkType
        __typename
        ... on Article{
          _meta{
            id
            uid
          }
          title
          image
          short_description
          date
          author {
            _linkType
            ... on Author {
              _meta {
                id
              }
              name_and_country
              short_description
              picture
            }
          }
        }
      }
    }
    primary{
      title
      caption
      show_recent_or_featured
      see_more_button_link {
        ... on _Document {
            _meta {
              uid
            }
          }
        }
        see_more_button_text
        }
      }
  
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyLast_articles_preview',
  fragmentName: 'HomeArticles',
}
