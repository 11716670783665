import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import style from './style.js'
import Link from 'next/link'
import { RichText } from 'lib/prismic'
import ArticleCard from '../../article-card'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query } from '@apollo/react-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import Title from '../../_ui/title'
import { ARTICLES_INFINITE_SCROLL_QUERY } from '../../../services/queries'

const Articles = (props) => {
  const { primary, classes } = props

  let total = 0
  props.additionalData.value.forEach((articlesChunk) => {
    total += articlesChunk.length
  })

  const [hasMore, setHasMore] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)
  const [articles, setArticles] = useState(props.additionalData.value[0])

  const fetchMoreData = () => {
    if (articles.length >= total) {
      setHasMore(false)
      return
    }

    setArticles(articles.concat(props.additionalData.value[activeIndex + 1]))
    setActiveIndex(activeIndex + 1)
  }

  const renderLoader = () => {
    if (props.additionalData.value && props.additionalData.value.length > 1) {
      return <h4>Loading...</h4>
    }
    return <div></div>
  }

  return (
    <div className={classes.outer}>
      <Container>
        <div className={classes.title}>
          {RichText.asText(primary.title) && (
            <Title mainTitle={primary.title} subTitle={primary.caption} />
          )}
        </div>

        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={articles.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={renderLoader()}
        >
          <div className={classes.articles}>
            <Grid container direction='row' spacing={4}>
              {articles.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Link
                    href={`/article/${item.node._meta.uid}`}
                    as={`/article/${item.node._meta.uid}`}
                    passHref
                  >
                    <a className={classes.link}>
                      <ArticleCard article={item.node} />
                    </a>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </div>
        </InfiniteScroll>
      </Container>
    </div>
  )
}

Articles.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(Articles)
