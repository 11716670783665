import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment ContactForm on PageBodyContactForm {
    __typename
    primary {
        title
        paragraph
        background_image
        hero_title
        hero_subtitle
    }
  
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyContactForm',
  fragmentName: 'ContactForm',
}
