import { textAlign } from '@material-ui/system'

const styles = (theme) => ({
  outer: {
    position: 'relative',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  fullHeight: {
    backgroundColor: 'rgba(16,39,65,0.5)',
    padding: '80px 0px',
  },
  title: {
    color: 'white',
    fontSize: 40,
    fontWeight: 600,
    fontFamily: 'Merriweather',
  },
  subtitle: {
    color: 'white',
    fontSize: 22,
    fontWeight: 400,
  },
  section1: {
    backgroundColor: theme.palette.primary[700],
    padding: '60px 0px',
    textAlign: 'center',
  },
  section2: {
    backgroundColor: theme.palette.primary[400],
    padding: '60px 0px',
    textAlign: 'center',
  },
  section3: {
    backgroundColor: '#102741',
    padding: '40px 50px',
  },
  section3title: {
    color: 'white',
    fontSize: 24,
  },
  section3content: {
    color: 'white',
    fontSize: 22,
    fontWeight: 400,
  },
  container: {
    backgroundColor: '#102741',
    width: '100%',
    margin: '0 auto',
  },
})

export default styles
