import gql from 'graphql-tag'
import { Fragments, Components } from './fragments'

const querySlices = (pages, slug) => {
  const page = pages.find((page) => page.node._meta.uid === slug)

  if (!page) return

  let bodyString = ''
  let fragmentString = ``
  page.node.body.forEach((body) => {
    if (Fragments.hasOwnProperty(body.__typename)) {
      bodyString += '...' + Fragments[body.__typename].fragmentName + '\n'
      fragmentString += `${Fragments[body.__typename].query.loc.source.body}\n`
    }
  })

  const string = `
    query MY_DOCUMENT_QUERY($slug: String!, $lang: String!) {
    	page(uid: $slug, lang: $lang) {
        meta_title,
        meta_description,
        og_image,
        body {
          __typename
          ${bodyString}
        }
      }
    }

    ${fragmentString}
  `
  return gql(string)
}

const renderSlices = (slices, layout, language, additionalData) => {
  return slices.map((slice, index) => {
    const res = (() => {
      if (Components.hasOwnProperty(slice.__typename)) {
        const NewComponent = Components[slice.__typename]
        const additional = additionalData
          ? additionalData.find((x) => x.key === slice.__typename)
          : null
        return (
          <div key={index}>
            <NewComponent
              {...slice}
              layout={layout}
              language={language}
              additionalData={additional}
            />
          </div>
        )
      }
    })()
    return res
  })
}

export { querySlices, renderSlices }
