import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import style from './style.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Query } from '@apollo/react-components'
import { graphql } from '@apollo/react-hoc'
import InfiniteScroll from 'react-infinite-scroll-component'
import TestimonyCard from '../../_ui/Testimonial-card'
import { TESTIMONIALS_INFINITE_SCROLL_QUERY } from '../../../services/queries'

const Testimonials = (props) => {
  const { classes, language } = props

  return (
    <div className={classes.outer}>
      <Container>
        <Query
          query={TESTIMONIALS_INFINITE_SCROLL_QUERY}
          variables={{ lang: language.prismicLanguage }}
        >
          {({ data, loading, error, fetchMore }) => {
            if (error) return <p>{error.message}</p>

            if (loading)
              return (
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              )

            let items = data.allTestimonials.edges

            return (
              <InfiniteScroll
                className={classes.infiniteScroll}
                dataLength={items.length}
                next={() =>
                  fetchMore({
                    variables: {
                      cursor: data.allTestimonials.pageInfo.endCursor,
                    },
                    updateQuery: (prevResult, { fetchMoreResult }) => {
                      const newEdges = fetchMoreResult.allTestimonials.edges
                      const pageInfo = fetchMoreResult.allTestimonials.pageInfo

                      return newEdges.length
                        ? {
                            allTestimonials: {
                              __typename: prevResult.allTestimonials.__typename,
                              edges: [
                                ...prevResult.allTestimonials.edges,
                                ...newEdges,
                              ],
                              pageInfo,
                            },
                          }
                        : prevResult
                    },
                  })
                }
                hasMore={data.allTestimonials.pageInfo.hasNextPage}
                loader={<h4>Loading...</h4>}
              >
                <div className={classes.articles}>
                  <Grid
                    container
                    direction='row'
                    justify='center'
                    spacing={10}
                    alignItems='flex-end'
                  >
                    {items.map((item, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4}>
                        <TestimonyCard field={item.node} i={index} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </InfiniteScroll>
            )
          }}
        </Query>
      </Container>
    </div>
  )
}

Testimonials.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(
  graphql(TESTIMONIALS_INFINITE_SCROLL_QUERY)(Testimonials)
)
