import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment Articles on PageBodyArticles {
  type
  primary {
    title
    caption
  }
}
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyArticles',
  fragmentName: 'Articles',
}
