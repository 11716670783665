import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'

function Statistics(props) {
  const { classes, primary } = props
  return (
    <div className={classes.container}>
      <Container justify='center'>
        <Grid container spacing={0}>
          <Grid
            container
            item
            direction='column'
            className={classes.section1}
            xs={12}
            md={3}
            alignItems='center'
            justify='center'
          >
            <Grid item>
              <div className={classes.title}>
                {RichText.asText(primary.first_section_title)}
              </div>
            </Grid>
            <Grid item>
              {' '}
              <div className={classes.subtitle}>
                {RichText.asText(primary.first_section_subtitle)}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='column'
            className={classes.section2}
            xs={12}
            md={3}
            alignItems='center'
            justify='center'
          >
            <Grid item>
              <div className={classes.title}>
                {RichText.asText(primary.second_section_title)}
              </div>
            </Grid>
            <Grid item>
              {' '}
              <div className={classes.subtitle}>
                {RichText.asText(primary.second_section_subtitle)}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction='column'
            className={classes.section3}
            xs={12}
            md={6}
            alignItems='flex-start'
            justify='flex-start'
          >
            <Grid item>
              <h2 className={classes.section3title}>
                {RichText.asText(primary.third_section_title)}
              </h2>
              <p className={classes.section3content}>
                {RichText.asText(primary.third_section_content)}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Statistics)
