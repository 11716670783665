import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment HeroFragment on PageBodyHero {
    primary {
      main_title
      sub_title
      left_button
      right_button
      video_id
      main_video {
        ... on _FileLink {
          url
        }
      }
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyHero',
  fragmentName: 'HeroFragment',
}
