const styles = (theme) => ({
  outer: {
    position: 'relative',
    padding: '80px 0px',
    textAlign: 'center',
    overflow: 'hidden',
  },
  title: {
    marginBottom: 40,
  },
  testimonialBox: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  testimonialText: {
    textAlign: 'left',
    padding: 20,
    paddingBottom: 70,
    backgroundColor: '#102741',
    color: 'white',
  },
  TestimonialsP: {},
  TestimonialsNameCountry: {
    color: theme.palette.primary[500],
    fontWeight: 700,
  },
  testemonialImage: {
    marginTop: '-50px',
    borderRadius: '50%',
    border: '2px solid' + theme.palette.primary[500],
    objectFit: 'cover',
    height: 100,
    width: 100,
  },
  fullHeight: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(16,39,65,0.5)',
  },
  buttons: {
    marginTop: 60,
  },
  button: {
    minWidth: 200,
  },
})

export default styles
