import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'

function TitleText(props) {
  const { classes, primary } = props

  return (
    <div className={classes.outer}>
      <Container>
        <Grid
          container
          className={classes.fullHeight}
          alignItems='center'
          justify='center'
        >
          <Grid item className={classes.titleOuter}>
            <h1 className={classes.title}>{RichText.asText(primary.title)}</h1>
            {RichText.render(primary.content)}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

TitleText.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TitleText)
