import { createStyles } from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      margin: '12px',
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[200],
      boxShadow: theme.shadows[2],
    },
    textField: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    select: {
      width: '100%',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    snackbarsuccess: {
      backgroundColor: theme.palette.success.main,
    },
    snackbarerror: {
      backgroundColor: theme.palette.error.main,
    },
  })

export default styles
