import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment SimpleForm on PageBodySimpleform {
    primary {
      title
      subtitle
      image
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodySimpleform',
  fragmentName: 'SimpleForm',
}
