import gql from 'graphql-tag'

export const allPagesQuery = gql`
  query PAGES_QUERY($lang: String) {
    allPages(lang: $lang) {
      edges {
        node {
          _meta {
            uid
          }
          body {
            __typename
          }
        }
      }
    }
  }
`

export const VALUED_PARTNERS_INFINITE_SCROLL_QUERY = gql`
  query VALUED_PARTNERS_QUERY($lang: String, $cursor: String) {
    allValued_partnerss(lang: $lang, after: $cursor) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          _meta {
            id
            uid
          }
          image
          name_and_country
        }
      }
    }
  }
`

export const TESTIMONIALS_INFINITE_SCROLL_QUERY = gql`
  query TESTIMONIALS_INFINITE_SCROLL_QUERY($lang: String, $cursor: String) {
    allTestimonials(lang: $lang, after: $cursor) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          _meta {
            id
            uid
          }
          name
          image
          testimony
        }
      }
    }
  }
`

export const singlePageQuery = gql`
  query PAGES_QUERY($lang: String, $slug: String) {
    allPages(lang: $lang, uid: $slug) {
      edges {
        node {
          _meta {
            uid
          }
          body {
            __typename
          }
        }
      }
    }
  }
`

export const SERVICES_GET_PATHS_QUERY = gql`
  query SERVICES_QUERY($lang: String!) {
    allProjectss(lang: $lang) {
      edges {
        node {
          _meta {
            uid
          }
        }
      }
    }
  }
`

export const ARTICLES_GET_PATHS_QUERY = gql`
  query ARTICLES_QUERY($lang: String!, $after: String) {
    allArticles(sortBy: date_DESC, lang: $lang, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          _meta {
            id
            uid
          }
        }
      }
    }
  }
`

export const ARTICLES_INFINITE_SCROLL_QUERY = gql`
  query ARTICLES_QUERY($lang: String!, $cursor: String) {
    allArticles(sortBy: date_DESC, lang: $lang, after: $cursor) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          _meta {
            id
            uid
          }
          title
          image
          short_description
          date
          author {
            _linkType
            ... on Author {
              _meta {
                id
              }
              name_and_country
              short_description
              picture
            }
          }
        }
      }
    }
  }
`
