import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment PageHtml on PageBodyHtml {
  primary {
    html
  }
}`

export default {
  query: gql(compress(query)),
  name: 'PageBodyHtml',
  fragmentName: 'PageHtml',
}
