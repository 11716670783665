import React from 'react'
import PropTypes from 'prop-types'
import SimpleForm from '../../simple-form/index'
import { withStyles, Container, Grid } from '@material-ui/core'

const styles = {
  formContainer: {
    padding: '2rem',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
}

const FormAndImage = (props) => {
  const { classes, primary } = props

  console.log(primary)

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={classes.formContainer}>
          <h2>{primary.title}</h2>
          <p>{primary.subtitle}</p>
          <SimpleForm />
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <img
            src={primary.image.url}
            alt={primary.image.alt || ''}
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

FormAndImage.propTypes = {
  classes: PropTypes.object.isRequired,
  primary: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormAndImage)
