import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment Testimonials on PageBodyTestimonials {
      primary{
        main_title
        sub_title
        video_button_text
        video_id
        see_more_button_text
        see_more_button_link {
          ... on _Document {
            _meta {
              uid
            }
          }
        }
      }
      fields{
        testimonial {
          ... on Testimonial {
            name
            testimony
            image
          }
        }
      }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyTestimonials',
  fragmentName: 'Testimonials',
}
