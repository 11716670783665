import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment HomeProjects on PageBodyProjects_preview {
  primary {
    title
    caption
    services_button_text
    services_button_link{ 
    ... on _Document {
      _meta {
        uid
      }
    }
    }
  }
  fields {
    service {
      ... on Projects {
        _meta {
          uid
        }
        name
        image
        short_description
      }
    }
  }
}`

export default {
  query: gql(compress(query)),
  name: 'PageBodyProjects_preview',
  fragmentName: 'HomeProjects',
}
