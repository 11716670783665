const styles = (theme) => ({
  outer: {
    position: 'relative',
    width: '100%',
    height: '700px',
  },
  fullHeight: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(16,39,65,0.3)',
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  outlinedButtonInnerText: {
    color: 'white',
  },
  timetemp: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingRight: 10,
  },
  temperatureText: {
    color: '#fff',
  },
  weatherIcon: {
    width: 50,
  },
  sub_title: {
    fontStyle: 'italic',
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  titleOuter: {
    maxWidth: 600,
    textAlign: 'center',
    color: '#fff',
    '@global': {
      '.title-underline': {
        borderBottom: '3px solid ' + theme.palette.primary[500],
      },
      h1: {
        fontSize: 40,
        [theme.breakpoints.down('sm')]: {
          fontSize: 30,
        },
      },
    },
  },
})

export default styles
