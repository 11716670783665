import { createStyles } from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    gridContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '12px',
    },
    firstGridItem: {
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
    },
    textContent: {},
    secondGridItem: {
      padding: '12px 24px',
    },
    hero_title: {
      color: 'white',
      fontSize: 30,
      fontWeight: 600,
      marginBottom: 20,
    },
    title: {
      color: theme.palette.primary[500],
      fontSize: 30,
      fontWeight: 600,
      marginBottom: 20,
    },
    paragraph: {
      color: 'black',
      fontSize: 22,
      fontWeight: 300,
      marginBottom: 20,
      fontStyle: 'italic',
    },
    outer: {
      position: 'relative',
      width: '100%',
      height: '500px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
    },
    fullHeight: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(16,39,65,0.3)',
    },
    outlinedButtonInnerText: {
      color: 'white',
    },
    sub_title: {
      fontStyle: 'italic',
      fontSize: 22,
      fontWeight: 400,
      marginBottom: 30,
    },
    titleOuter: {
      maxWidth: 600,
      textAlign: 'center',
      color: '#fff',
      '@global': {
        '.title-underline': {
          borderBottom: '3px solid ' + theme.palette.primary[500],
        },
        h1: {
          fontSize: 40,
        },
      },
    },
  })

export default styles
