const styles = (theme) => ({
  outer: {
    paddingTop: 100,
    paddingBottom: 100,
  },
  icon: {
    marginBottom: 20,
    fontSize: 40,
    display: 'block',
  },
  mapComponent: {
    height: 400,
  },
})

export default styles
