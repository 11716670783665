import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment DownloadEbook on PageBodyDownloadebook {
    primary {
      ebook_image
      title
      content
      input_placeholder
      button_text
      download_button_text
      link_to_file{
        ... on _FileLink{
          url
        }
      }
      
      
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyDownloadebook',
  fragmentName: 'DownloadEbook',
}
