import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import style from './style.js'
import Link from 'next/link'
import { RichText } from 'lib/prismic'
import ArticleCard from '../../article-card'
import { Query } from '@apollo/react-components'
import Title from '../../_ui/title'
import { ARTICLES_INFINITE_SCROLL_QUERY } from '../../../services/queries'

function HomeArticlesComponent(props) {
  const { primary, fields, classes, language } = props

  const renderArticles = (articles) => {
    return articles.map((article, index) => {
      return (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <Link
            href={`/article?slug=${article._meta.uid}`}
            as={`/article/${article._meta.uid}`}
            passHref
          >
            <a className={classes.link}>
              <ArticleCard article={article} />
            </a>
          </Link>
        </Grid>
      )
    })
  }

  const renderArticlesContainer = (articles) => {
    return (
      <Container>
        {(primary.title || primary.caption) && (
          <Title mainTitle={primary.title} subTitle={primary.caption} />
        )}
        <Grid container spacing={6} className={classes.articles}>
          {renderArticles(articles)}
        </Grid>
        {primary.see_more_button_link && primary.see_more_button_text && (
          <Grid className={classes.seeMoreButton} item>
            <Link
              href={`/index?slug=${primary.see_more_button_link._meta.uid}`}
              as={`/${primary.see_more_button_link._meta.uid}`}
              passHref
            >
              <Button component='a' variant='contained'>
                {RichText.asText(primary.see_more_button_text)}
              </Button>
            </Link>
          </Grid>
        )}
      </Container>
    )
  }

  /// SHOW SELECTED ARTICLES
  if (primary.show_recent_or_featured && fields && fields.length > 0) {
    return (
      <div className={classes.outer}>
        {renderArticlesContainer(fields.map((x) => x.last_articles))}
      </div>
    )
  }
  //SHOW THE LATEST THREE ARTICLES
  return (
    <div className={classes.outer}>
      <Query
        query={ARTICLES_INFINITE_SCROLL_QUERY}
        variables={{ lang: language.prismicLanguage }}
      >
        {({ data, loading, error, fetchMore }) => {
          if (loading) return <div>loading</div>

          return renderArticlesContainer(
            data.allArticles.edges
              .slice(0, 3)
              .filter((x) => x.node)
              .map((x) => x.node)
          )
        }}
      </Query>
    </div>
  )
}

HomeArticlesComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(HomeArticlesComponent)
