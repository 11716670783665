const styles = (theme) => ({
  outer: {
    position: 'relative',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  fullHeight: {
    backgroundColor: 'white',
    padding: '80px 0px',
  },
  title: {
    color: 'black',
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 20,
  },
  content: {
    fontSize: 22,
    fontWeight: 400,
  },
  titleOuter: {
    maxWidth: 900,
    textAlign: 'center',
    color: 'black',
    '@global': {
      p: {
        fontSize: 22,
        fontWeight: 400,
      },
    },
  },
})

export default styles
