import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment SectionWithQuote on PageBodySection_with_text_  {
     primary{
        text
        back_ground_image
        quote_author
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodySection_with_text_',
  fragmentName: 'SectionWithQuote',
}
