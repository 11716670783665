import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const defaultVariables = {
  name: 'PageBodyCall_to_action',
  fragmentName: 'callToAction',
}

function query(variables) {
  return `fragment ${variables.fragmentName} on ${variables.name}{
    primary{
      title
      text
      button_text
      background_color
      button_type
      button_link{
        ... on _ExternalLink {
          url
        }
        ... on _FileLink{
          url
        }
        ... on _ImageLink{
          url
        }
        ... on _Document {
          _meta {
            uid
            type
          }
        }
      }
    }
  }
`
}
export function queryString(variables) {
  return {
    query: gql(compress(query(variables))),
    ...variables,
  }
}

export default queryString(defaultVariables)
