import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment regularHeroFragment on PageBodyRegular_hero{
    primary{
      title
      sub_title
      background_image
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyRegular_hero',
  fragmentName: 'regularHeroFragment',
}
