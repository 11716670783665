import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment Statistics on PageBodyStatistics {
    primary{
      first_section_title
      first_section_subtitle
      second_section_title
      second_section_subtitle
      third_section_title
      third_section_content
      
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyStatistics',
  fragmentName: 'Statistics',
}
