const styles = (theme) => ({
  outer: {
    padding: '80px 0px',
  },
  content: {
    '@global': {
      p: {
        fontSize: 22,
      },
    },
  },
})

export default styles
