import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import style from './style.js'
import { RichText } from 'lib/prismic'

function TeamMembers(props) {
  const { primary, fields, classes } = props

  const renderTeamMembers = () => {
    return fields.map((member, index) => {
      return (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <img
            className={classes.memberImage}
            src={member.pic.url}
            alt={member.pic.alt || ''}
          />
          <h4 className={classes.name}>{RichText.asText(member.name)}</h4>
          <label className={classes.position}>
            {RichText.asText(member.position)}
          </label>
          {/* <div className={classes.desc}>{RichText.asText(member.description)}</div> */}
        </Grid>
      )
    })
  }

  return (
    <div className={classes.outer}>
      <Container>
        <h1>{RichText.asText(primary.title)}</h1>

        <Grid container spacing={6}>
          {renderTeamMembers()}
        </Grid>
      </Container>
    </div>
  )
}

TeamMembers.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(TeamMembers)
