const styles = (theme) => ({
  title: {
    marginBottom: 40,
  },
  testimonialBox: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  testimonialText: {
    textAlign: 'center',
    padding: 20,
    paddingBottom: 70,
    backgroundColor: '#102741',
    color: 'white',
  },

  TestimonialsNameCountry: {
    color: theme.palette.primary[400],
    fontWeight: 700,
  },
  testemonialImage: {
    marginTop: '-50px',
    borderRadius: '50%',
    border: '2px solid' + theme.palette.primary[500],
    objectFit: 'cover',
    height: 100,
    width: 100,
  },
})

export default styles
