import React, { useState } from 'react'
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Snackbar,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { countries } from '../form-dialog/contact-details/countries'
import { useLayout } from '../../lib/layoutContext'

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode
}

function SimpleForm(props) {
  const layout = useLayout()
  const { classes } = props
  const [submitting, setSubmitting] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const [value, setValue] = useState({
    firstName: '',
    firstNameTouched: false,
    lastName: '',
    email: '',
    emailValid: false,
    emailTouched: false,
    phone: '',
    country: null, // Initialize as null or an empty object
    countryTouched: false,
    role: '',
    message: '',
  })

  const handleChange = (input) => (event) => {
    if (input === 'email') {
      setValue({
        ...value,
        [input]: event.target.value,
        emailValid: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value),
      })
    } else {
      setValue({ ...value, [input]: event.target.value })
    }
  }

  const handleSubmit = async () => {
    if (
      value.firstName.trim() === '' ||
      value.email.trim() === '' ||
      !value.emailValid ||
      !value.country
    ) {
      return
    }

    setSubmitting(true)

    const values = {
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      phone: value.phone,
      country: value.country.label,
      role: value.role,
      message: value.message,
    }

    const body = JSON.stringify({
      name: `${values.firstName} ${values.lastName}`,
      email: value.email,
      organization: '',
      before: '',
      phone: values.phone,
      countryName: values.country,
      timezone: '',
      iam: values.role,
      pastAgency: '',
      heared: '',
      type: '',
      fromDate: '',
      toDate: '',
    })

    // Implement form submission logic here

    const sendMail = await fetch('/api/send-mail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    })
    if (sendMail.ok == false) {
      console.error('Error sending email:', error)
      setSnackbarMessage(
        layout.snackbar_email_error_message ||
          'An error occurred while sending the email. Please try again later.'
      )
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setSubmitting(false)
    }

    const zohoCRM = await fetch('/api/zoho', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    })
    if (zohoCRM.ok == true) {
      setSnackbarMessage(
        layout.snackbar_success_message || 'Form submitted successfully'
      )
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
    } else {
      console.error('Error sending data to Zoho CRM:', error)
      setSnackbarMessage(
        layout.snackbar_zohocrm_error_message ||
          'An error occurred while submitting the form. Please try again later.'
      )

      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
    // Reset form fields
    setValue({
      firstName: '',
      firstNameTouched: false,
      lastName: '',
      email: '',
      emailValid: false,
      emailTouched: false,
      phone: '',
      country: null,
      countryTouched: false,
      role: '',
      message: '',
    })
    setSubmitting(false)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <div className={classes.formContainer}>
      <TextField
        label={layout.first_name || 'First Name'}
        className={classes.textField}
        margin='normal'
        value={value.firstName}
        onChange={handleChange('firstName')}
        onBlur={() => setValue({ ...value, firstNameTouched: true })}
        required
        error={value.firstName.trim() === '' && value.firstNameTouched}
        helperText={
          (value.firstName.trim() === '' &&
            value.firstNameTouched &&
            layout.required_first_name) ||
          'First name is required'
        }
      />
      <TextField
        label={layout.last_name || 'Last Name'}
        className={classes.textField}
        margin='normal'
        value={value.lastName}
        onChange={handleChange('lastName')}
      />
      <TextField
        label={layout.simple_email || 'Email'}
        className={classes.textField}
        margin='normal'
        value={value.email}
        onChange={handleChange('email')}
        onBlur={() => setValue({ ...value, emailTouched: true })}
        required
        error={
          (value.emailTouched && !value.emailValid) ||
          (value.email.trim() === '' && value.emailTouched)
        }
        helperText={
          (value.emailTouched && !value.emailValid && layout.invalid_email) ||
          'Please enter a valid email' ||
          (value.email.trim() === '' &&
            value.emailTouched &&
            layout.required_email) ||
          'Email is required'
        }
      />

      <TextField
        label={layout.phone || 'Phone Number'}
        className={classes.textField}
        margin='normal'
        value={value.phone}
        onChange={handleChange('phone')}
      />
      <Autocomplete
        id='contact-details-countryName'
        options={countries}
        classes={{
          option: classes.option,
        }}
        value={value.country}
        autoHighlight
        onChange={(e, newValue) => {
          setValue({ ...value, country: newValue, countryTouched: true })
        }}
        onBlur={() => setValue({ ...value, countryTouched: true })}
        getOptionLabel={(option) => {
          return option.label
        }}
        renderOption={(option) => (
          <React.Fragment>
            <span>{countryToFlag(option.code)}</span>
            {option.label} ({option.code})
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={layout.simple_country}
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: 'disabled', // disable autocomplete and autofill
            }}
            required
            error={!value.country && value.countryTouched}
            helperText={
              (!value.country &&
                value.countryTouched &&
                layout.required_country) ||
              'Country is required'
            }
          />
        )}
        fullWidth
      />
      {/*  */}
      <Select
        label={layout.role || 'Role'}
        className={classes.select}
        value={value.role}
        onChange={handleChange('role')}
        displayEmpty
        renderValue={(selected) =>
          selected ? selected : layout.render_value_role || 'Select a role'
        }
      >
        <MenuItem value='Tour Agent'>Tour Agent</MenuItem> {/*  */}
        <MenuItem value='Pastor'>Pastor</MenuItem> {/*  */}
        <MenuItem value='Individual'>Individual</MenuItem> {/*  */}
      </Select>
      <TextField
        label={layout.message || 'Message'}
        className={classes.textField}
        margin='normal'
        multiline
        value={value.message}
        onChange={handleChange('message')}
      />
      {/*  */}
      <Button
        variant='contained'
        color='primary'
        className={classes.submitButton}
        onClick={handleSubmit}
        disabled={
          !value.firstName ||
          !value.email ||
          !value.emailValid ||
          !value.country ||
          submitting
        }
      >
        {layout?.submit_button[0].text}
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        ContentProps={{
          className: classes[`snackbar${snackbarSeverity}`],
        }}
      />
    </div>
  )
}

SimpleForm.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(SimpleForm)
