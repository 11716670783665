import React, { useState, useEffect } from 'react'
import PropTypes, { any } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid, Button } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'
import VideoCover from 'react-video-cover'
import ModalVideo from 'react-modal-video'
import ContactButton from '../../_ui/contact-button/button'
import Clock from 'react-live-clock'
function Hero(props) {
  const { classes, fields, primary } = props

  const videoOptions = {
    src: primary.main_video.url,
    playsInline: true,
    autoPlay: true,
    muted: true,
    loop: true,
  }
  const [isOpen, setIsOpen] = useState(false)
  const [temperature, setTemperature] = useState(null)
  const [weahtherIcon, setWeatherIcon] = useState(null)
  const openModal = () => {
    setIsOpen(true)
  }

  const fetchTemperature = async () => {
    const res = await fetch(
      'https://api.openweathermap.org/data/2.5/weather?q=Jerusalem,il&APPID=d3de128c11445046141b30e2c1696477&units=metric'
    )

    res.json().then((res) => {
      setTemperature(res.main.temp)
      setWeatherIcon(res.weather[0].icon)
    })
  }

  useEffect(() => {
    fetchTemperature()
  }, [])

  return (
    <div className={classes.outer}>
      <VideoCover
        remeasureOnWindowResize
        videoOptions={videoOptions}
      ></VideoCover>
      <Grid
        container
        className={classes.fullHeight}
        alignItems='center'
        justify='center'
      >
        <Grid item className={classes.titleOuter}>
          <Container>
            {RichText.render(primary.main_title)}
            <p className={classes.sub_title}>
              {RichText.asText(primary.sub_title)}
            </p>
            <Grid container justify='center' alignItems='center' spacing={5}>
              <Grid item>
                <ContactButton variant='contained'>
                  {RichText.asText(primary.left_button)}
                </ContactButton>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  onClick={() => openModal()}
                  className={classes.outlinedButton}
                >
                  <span className={classes.outlinedButtonInnerText}>
                    {RichText.asText(primary.right_button)}
                  </span>
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      {temperature && (
        <Grid
          container
          spacing={0}
          direction='row'
          justify='flex-end'
          className={classes.timetemp}
        >
          <Grid item>
            {' '}
            <p>
              <span className={classes.temperatureText}>
                {' '}
                {temperature}&#8451; in Jerusalem
              </span>{' '}
            </p>
          </Grid>
          <Grid item>
            {
              <img
                className={classes.weatherIcon}
                src={`https://openweathermap.org/img/wn/${weahtherIcon}@2x.png`}
                alt=''
              ></img>
            }
          </Grid>
        </Grid>
      )}
      {/*
              <Grid item>
              <p>Time Is:
              
              <Clock format={'HH:mm:ss'} ticking={true} timezone={'Asia/Jerusalem'} /></p>
              
              </Grid>*/}
      <ModalVideo
        channel='youtube'
        isOpen={isOpen}
        videoId={primary.video_id}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

Hero.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Hero)
