import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style.js'
import { RichText } from 'lib/prismic'
import clsx from 'clsx'

const useStyles = makeStyles(style)

export default function Title(props) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.heading, props.className)}>
      {props.mainTitle && (
        <h2 className={classes.title}>{RichText.asText(props.mainTitle)}</h2>
      )}
      {props.subTitle && (
        <p className={classes.description}>{RichText.asText(props.subTitle)}</p>
      )}
    </div>
  )
}
