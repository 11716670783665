import { fontWeight } from '@material-ui/system'

const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    textAlign: 'center',
    backgroundColor: '#fff',
  },

  projects: {
    textAlign: 'center',
  },
  name: {
    color: theme.palette.primary[500],
    fontSize: 17,
    fontFamily: 'Montserrat',
    fontWeight: 900,
    marginBottom: 0,
  },
  position: {
    color: theme.palette.primary[500],
    fontSize: 17,
    fontFamily: 'Montserrat',
    fontWeight: 500,
  },
  desc: {
    padding: '0 40px',
    marginTop: 20,
  },
  memberImage: {
    width: '100%',
  },
})

export default styles
