import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import style from './style.js'
import { RichText } from 'lib/prismic'
import TruncateMarkup from 'react-truncate-markup'

function ArticleCard(props) {
  const { article, classes } = props

  return (
    <Card>
      <CardMedia
        className={classes.media}
        image={article.image.small.url}
        aria-label={article.image.alt || ''}
        role='img'
        alt={article.image.alt || ''}
      />
      <CardContent className={classes.cardContent}>
        <h3 className={classes.title}>{RichText.asText(article.title)}</h3>
        {
          <time className={classes.date} datetime={article.date}>
            {new Intl.DateTimeFormat('en-US', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              literal: '.',
            }).format(new Date(article.date))}
          </time>
        }

        <TruncateMarkup lines={2}>
          <p>{RichText.asText(article.short_description)}</p>
        </TruncateMarkup>
        {article.author && (
          <Grid
            container
            className={classes.author}
            spacing={4}
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            {article.author.picture && (
              <Grid item>
                <img
                  className={classes.authorImage}
                  src={article.author.picture.url}
                  alt={article.author.picture.alt || ''}
                />
              </Grid>
            )}
            <Grid item>
              <label className={classes.authorName}>
                By {RichText.asText(article.author.name_and_country)}
              </label>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

ArticleCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ArticleCard)
