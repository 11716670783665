const styles = (theme) => ({
  outer: {
    padding: '80px 0px',
  },
  outlinedButtonInnerText: {
    color: 'white',
  },
  subTitle: {
    fontSize: '1.3rem',
    marginBottom: 30,
  },

  section3: {
    height: 800,
    textAlign: 'center',
  },
  titleOuter: {
    maxWidth: 900,
    textAlign: 'center',
    color: 'black',

    '@global': {
      '.title-underline': {
        borderBottom: '3px solid ' + theme.palette.primary[500],
      },
    },
  },
})

export default styles
