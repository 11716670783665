const styles = (theme) => ({
  card: {
    backgroundColor: '#F3F3F3',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 19,
    marginTop: 0,
    color: theme.palette.primary[500],
  },
  cardContent: {
    padding: 20,
    textAlign: 'left',
  },
})

export default styles
