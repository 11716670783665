const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    textAlign: 'center',
    backgroundColor: '#F3F3F3',
    '.slick-dots': {
      marginTop: 80,
    },
  },
  grid: {
    outline: 'none',
  },
  image: {
    width: 200,
    height: 200,
    objectFit: 'cover',
    borderRadius: '50%',
    border: '3px solid' + theme.palette.primary[600],
    margin: '0px auto',
  },
  slider: {
    marginTop: 40,
    marginBottom: 55,
    '@global': {
      li: {
        alignItems: 'flex-start',
      },
    },
  },
  name: {
    color: theme.palette.primary[600],
    fontWeight: '600',
  },
  gridItem: {
    position: 'relative',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  gridItemName: {
    margin: '0 auto',
  },

  small: {
    fontSize: 21,
  },
  buttons: {
    marginTop: 5,
  },
  container: {
    maxWidth: 900,
    margin: '0px auto',
  },
  black: {
    color: theme.palette.softBlack.secondary,
    borderColor: theme.palette.softBlack.secondary,
    '&:hover': {
      borderColor: theme.palette.softBlack.primary,
      backgroundColor: theme.palette.softBlack.hint,
    },
  },
  subtitle: {
    marginTop: 0,
    marginBottom: 60,
    fontSize: 22,
    fontWeight: 300,
  },
  title: {
    fontWeight: 500,
    margin: '0px auto',
  },
  sliderContainer: {
    backgroundColor: 'white',
  },
})

export default styles
