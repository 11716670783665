import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import style from './style.js'
import Link from 'next/link'
import { RichText } from 'lib/prismic'
import ProjectCard from '../../project-card'
import Title from '../../_ui/title'

function HomeProjectsComponent(props) {
  const { primary, fields, classes } = props
  const renderProjects = () => {
    return fields
      .filter((x) => x.service)
      .map((service, index) => {
        return (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Link
              href={`/service?slug=${service.service._meta.uid}`}
              as={`/service/${service.service._meta.uid}`}
              passHref
            >
              <a className={classes.projectLink}>
                <ProjectCard project={service.service} />
              </a>
            </Link>
          </Grid>
        )
      })
  }

  return (
    <div className={classes.outer}>
      <Container>
        {RichText.asText(primary.title) && (
          <Title mainTitle={primary.title} subTitle={primary.caption} />
        )}

        <Grid container spacing={6} className={classes.projects}>
          {renderProjects()}
        </Grid>
        {primary.services_button_link && (
          <Grid className={classes.seeMoreButton} item>
            <Link
              href={`/index?slug=${primary.services_button_link._meta.uid}`}
              as={`/${primary.services_button_link._meta.uid}`}
              passHref
            >
              <Button
                component='a'
                variant='contained'
                className={classes.button}
              >
                {RichText.asText(primary.services_button_text)}
              </Button>
            </Link>
          </Grid>
        )}
      </Container>
    </div>
  )
}

HomeProjectsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(HomeProjectsComponent)
