const styles = (theme) => ({
  outer: {
    backgroundColor: '#F3F3F3',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    height: 250,
    cursor: 'pointer',
  },
  text: {
    fontSize: 22,
    maxWidth: '70%',
    margin: '0 auto',
  },
  container: {
    textAlign: 'center',
    padding: '80px 0px',
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
  },
  slides: {
    marginTop: 40,
  },
})

export default styles
