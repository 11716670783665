import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import style from './style.js'
import { RichText } from 'lib/prismic'
import clsx from 'clsx'
import ContactButton from '../../_ui/contact-button/button'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import { useLayout } from '../../../lib/layoutContext'

const mapStyle = require('./map-style.json')
const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultOptions={{
        styles: mapStyle,
        zoomControl: true,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
      }}
      defaultZoom={16}
      defaultCenter={{
        lat: props.latitude + 0.001,
        lng: props.longitude + 0.001,
      }}
    >
      {props.isMarkerShown && (
        <Marker
          icon={{
            fillColor: props.color,
            fillOpacity: 1,
            strokeColor: '',
            strokeWeight: 0,
            path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
          }}
          position={{ lat: props.latitude, lng: props.longitude }}
        />
      )}
    </GoogleMap>
  ))
)
function ContactPage(props) {
  const { primary, classes } = props
  const layout = useLayout()

  const renderSocialMediaButtons = (socialLinks) => {
    return socialLinks.map((link, i) => {
      return (
        <a
          key={i}
          href={link.url.url}
          aria-label={`${link.social_name} (${layout.main_blank_link_label})`}
          target='_blank'
          className={classes.icon}
        >
          <i
            role='presentation'
            className={clsx(link.icon_name, classes.icon)}
          />
        </a>
      )
    })
  }

  return (
    <div>
      <Container maxWidth='lg' className={classes.outer}>
        <Grid container direction='row' spacing={6} justify='space-between'>
          <Grid item xs>
            <h2>{RichText.asText(primary.left_title)}</h2>
            <p>{RichText.asText(primary.left_section)}</p>
            <ContactButton variant='contained' className={classes.button}>
              {RichText.asText(primary.contact_button_text)}
            </ContactButton>
          </Grid>
          <Grid item xs>
            <h2>{RichText.asText(primary.middle_title)}</h2>
            {RichText.render(primary.contact_details)}
          </Grid>
          <Grid item xs>
            <h2>{RichText.asText(primary.right_title)}</h2>
            {renderSocialMediaButtons(layout.footer_contact_social)}
          </Grid>
        </Grid>
      </Container>
      <div className={classes.map}>
        <MyMapComponent
          isMarkerShown
          longitude={primary.map_point?.longitude}
          latitude={primary.map_point?.latitude}
          color='primary'
          googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyB_w2TRlKMN7Z_oD9ZMYfUICLKWiUK1844&v=3.exp&libraries=geometry,drawing,places'
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className={classes.mapComponent} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </div>
  )
}

ContactPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ContactPage)
