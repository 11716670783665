import React from 'react'
import PropTypes, { any } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid, Button } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'
import VideoCover from 'react-video-cover'
import ContactButton from '../../_ui/contact-button/button'
//import {Link} from 'routes'
import Link from 'next/link'

function MidPageContent(props) {
  const { classes, fields, primary } = props

  return (
    <div className={classes.outer}>
      <Grid
        container
        className={classes.fullHeight}
        alignItems='center'
        justify='center'
      >
        <Grid item className={classes.titleOuter}>
          <Container>
            <h2>{RichText.asText(primary.main_title)}</h2>
            <p className={classes.subTitle}>
              {RichText.asText(primary.sub_title)}
            </p>
            <Grid container justify='center' alignItems='center' spacing={5}>
              <Grid item>
                <ContactButton variant='contained' className={classes.button}>
                  {RichText.asText(primary.left_button)}
                </ContactButton>
              </Grid>
              <Grid item>
                <Link
                  href={`/index?slug=${primary.right_button_link._meta.uid}`}
                  as={`/${primary.right_button_link._meta.uid}`}
                  passHref
                >
                  <Button variant='outlined' component='a'>
                    {RichText.asText(primary.right_button)}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

MidPageContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MidPageContent)
