import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment TitleTextAlignLeft on PageBodyTitle_text_align_left {
    primary {
      title
      text
      
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyTitle_text_align_left',
  fragmentName: 'TitleTextAlignLeft',
}
