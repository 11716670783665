const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    textAlign: 'center',
    backgroundColor: '#F3F3F3',
  },
  articles: {
    marginTop: 20,
    textAlign: 'left',
  },
  buttons: {
    marginTop: 30,
  },
  description: {
    maxWidth: 700,
    margin: '0px auto',
  },
  link: {
    textDecoration: 'none',
  },
  seeMoreButton: {
    marginTop: 30,
  },
})

export default styles
