import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment HomePageFragment on PageBodyHomePage {
    primary{
      main_title
      sub_title
      left_button
      right_button
      right_button_link{ 
        ... on _Document {
          _meta {
            uid
          }
        }
        }
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyHomePage',
  fragmentName: 'HomePageFragment',
}
