import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment AllValuedPartners on PageBodyAll_valued_partners {
  primary {
    title
  }
}
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyAll_valued_partners',
  fragmentName: 'AllValuedPartners',
}
