import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment TeamMembers on PageBodyTeam_members {
      primary{
          title
        }
      fields{
          pic
          position
          description
          name
        }
    }
  
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyTeam_members',
  fragmentName: 'TeamMembers',
}
