const styles = (theme) => ({
  outer: {
    position: 'relative',
    width: '100%',
  },
  fullHeight: {
    backgroundColor: 'rgba(16,39,65,0.5)',
    padding: '80px 0px',
  },
  container: {
    maxWidth: 800,
  },
  title: {
    color: 'white',
    fontSize: 30,
    fontWeight: 600,
    marginBottom: 20,
  },
  text: {
    color: 'white',
    fontSize: 22,
    fontWeight: 300,
    marginBottom: 20,
    fontStyle: 'italic',
  },
  quoteAuthor: {
    color: theme.palette.primary[500],
    fontSize: 25,
    fontWeight: 600,
  },
  titleOuter: {
    maxWidth: 1100,
    textAlign: 'center',
    color: 'black',
  },
})

export default styles
