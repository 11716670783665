import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment TourismInfo on PageBodyTourism_info {
  fields{
    title
    content
    
  }
}`

export default {
  query: gql(compress(query)),
  name: 'PageBodyTourism_info',
  fragmentName: 'TourismInfo',
}
