import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const defaultVariables = {
  name: 'ProjectsBody1Gallery',
  fragmentName: 'Gallery',
}

function query(variables) {
  return `fragment ${variables.fragmentName} on ${variables.name}{
    primary{
      title
      caption
    }
    fields{
      picture
    }
  }
`
}

export function queryString(variables) {
  return {
    query: gql(compress(query(variables))),
    ...variables,
  }
}

export default queryString(defaultVariables)
