import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import style from './style.js'
import { Link as LinkHelper, RichText } from 'lib/prismic'

function TourismInfo(props) {
  const { primary, fields, classes } = props

  const renderInfoTabs = () => {
    return fields.map((field, index) => {
      return (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <h2>{RichText.asText(field.title)}</h2>
          <div className={classes.content}>
            {RichText.asText(field.content)}
          </div>
        </Grid>
      )
    })
  }

  return (
    <div className={classes.outer}>
      <Container>
        <Grid
          container
          spacing={6}
          direction='row'
          className={classes.projects}
        >
          {renderInfoTabs()}
        </Grid>
      </Container>
    </div>
  )
}

TourismInfo.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(TourismInfo)
