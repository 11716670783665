const styles = (theme) => ({
  outer: {
    position: 'relative',
    padding: '80px 0px',
    backgroundColor: '#F3F3F3',
    overflow: 'hidden',
  },
  title: {
    color: '#000',
    margin: 0,
  },
  description: {
    margin: 0,
    fontSize: '1.3rem',
    maxWidth: 800,
    paddingTop: 10,
    marginBottom: 20,
    color: '#000',
  },
  inputContainer: {
    '& label': {
      color: theme.palette.primary[600],
    },
  },
})

export default styles
