import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import style from './style.js'
import { RichText } from 'lib/prismic'
function TestimonialCard(props) {
  const { field, i, classes } = props
  return (
    <React.Fragment>
      <div className={classes.testimonialText}>
        <div className={classes.TestimonialsP}>
          {RichText.render(field.testimony)}
        </div>
        <div className={classes.TestimonialsNameCountry}>
          {RichText.asText(field.name)}
        </div>
      </div>
      <img
        className={classes.testemonialImage}
        src={field.image.url}
        alt={field.image.alt || ''}
      />
    </React.Fragment>
  )
}

TestimonialCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(TestimonialCard)
