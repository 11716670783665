import React, { useState } from 'react'
import PropTypes, { any } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid, Button } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'
import Title from '../../_ui/title'
import ModalVideo from 'react-modal-video'
import Link from 'next/link'
import TestimonyCard from '../../_ui/Testimonial-card'

function Testimonials(props) {
  const { classes, fields, primary } = props
  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true)
  }
  const testimonials = fields.map((field, i) => {
    return (
      <Grid item key={i} xs={12} sm={6} md={4}>
        <TestimonyCard field={field.testimonial} />
      </Grid>
    )
  })
  return (
    <div className={classes.outer}>
      <Container>
        <Title
          className={classes.title}
          mainTitle={primary.main_title}
          subTitle={primary.sub_title}
        />
        <Grid
          container
          spacing={10}
          alignItems='flex-end'
          justifyContent='center'
        >
          {testimonials}
        </Grid>
        <Grid
          container
          spacing={5}
          direction='row'
          justify='center'
          className={classes.buttons}
        >
          <Grid item>
            <Button
              className={classes.button}
              variant='contained'
              onClick={() => openModal()}
            >
              <span>{RichText.asText(primary.video_button_text)}</span>
            </Button>
          </Grid>
          <Grid item>
            <Link
              href={`/index?slug=${primary.see_more_button_link._meta.uid}`}
              as={`/${primary.see_more_button_link._meta.uid}`}
              passHref
            >
              <Button
                className={classes.button}
                variant='outlined'
                component='a'
              >
                {RichText.asText(primary.see_more_button_text)}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <ModalVideo
        channel='youtube'
        isOpen={isOpen}
        videoId={primary.video_id}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

Testimonials.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Testimonials)
