import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import style from './style.js'
import { RichText } from 'lib/prismic'
import TruncateMarkup from 'react-truncate-markup'

function ProjectCard(props) {
  const { classes, project } = props

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={project.image.small.url}
        aria-label={project.image.alt || ''}
        role='img'
        alt={project.image.alt || ''}
      />
      <CardContent className={classes.cardContent}>
        <h3 className={classes.name}>{RichText.asText(project.name)}</h3>
        <TruncateMarkup lines={3}>
          <p>{RichText.asText(project.short_description)}</p>
        </TruncateMarkup>
      </CardContent>
    </Card>
  )
}

ProjectCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ProjectCard)
