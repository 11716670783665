const req = require.context('./', true, /\/(.*)\/index.js$/)
let components = {}
let fragments = {}

req.keys().forEach((filename) => {
  const file = req(filename)

  fragments[file.default?.fragment.name] = file.default?.fragment
  components[file.default?.fragment.name] = file.default?.component
})

export const Fragments = fragments
export const Components = components
