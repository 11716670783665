import React, { useState } from 'react'
import PropTypes, { any } from 'prop-types'
import {
  withStyles,
  Container,
  Grid,
  Button,
  TextField,
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { useRouter } from 'next/router'

function DownloadEBook(props) {
  const initialValues = {
    email: '',
  }
  const router = useRouter()
  const { classes, primary, layout } = props
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar({ open: false, message: '' })
  }

  const fetchZoho = async (values) => {
    const zohoCRM = await fetch(`${router.basePath}/api/zoho`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: values.email,
      }),
    })
    if (zohoCRM.status === 200) {
      setOpenSnackbar({ open: true, message: 'Success' })
    } else {
      setOpenSnackbar({ open: true, message: 'Error' })
    }
  }

  const fetchEmail = async (values) => {
    const rawResponse = await fetch(
      `${router.basePath}/api/subscribe-to-newsletter`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: values.email }),
      }
    )
    if (rawResponse.status === 200) {
      setOpenSnackbar({ open: true, message: 'Success' })
    } else {
      setOpenSnackbar({ open: true, message: 'Error' })
    }
  }

  return (
    <div className={classes.outer}>
      <Container>
        <Grid container justify='center' direction='row' spacing={10}>
          <Grid item className={classes.ebook_image}>
            <img
              src={primary.ebook_image.url}
              alt={primary.ebook_image.alt || ''}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            justify='center'
            alignItems='center'
          >
            <Grid item>
              <h2 className={classes.title}>
                {RichText.asText(primary.title)}
              </h2>
              <p className={classes.description}>
                {RichText.asText(primary.content)}
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email('Email is invalid')
                    .required('Email is required'),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(false)
                  fetchZoho(values)
                  fetchEmail(values)
                  openSnackbar.message !== 'Error'
                    ? window.open(primary.link_to_file.url, '_blank')
                    : null
                  resetForm()
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form>
                    <Grid container alignItems='center' spacing={2}>
                      <Grid item xs={true} className={classes.inputContainer}>
                        <TextField
                          id='download-ebook-email'
                          required
                          className={classes.textField}
                          inputProps={{
                            'aria-required': 'true',
                          }}
                          InputProps={{ className: classes.input }}
                          type='email'
                          onChange={handleChange('email')}
                          fullWidth
                          label={RichText.asText(primary.input_placeholder)}
                          value={values.email}
                          margin='normal'
                          helperText={touched.email ? errors.email : ''}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => handleSubmit()}
                          variant='contained'
                          disabled={isSubmitting}
                        >
                          {RichText.asText(primary.button_text)}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar.open}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{openSnackbar.message}</span>}
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  )
}

DownloadEBook.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DownloadEBook)
