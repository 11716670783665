import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import Link from 'next/link'
import Container from '@material-ui/core/Container'
import style from './style.js'
import { RichText } from 'lib/prismic'
import Title from '../../_ui/title'
import dynamic from 'next/dynamic'

const CarouselComponent = dynamic(() => import('../../carousel'), {
  ssr: false,
})

import '@brainhubeu/react-carousel/lib/style.css'

class TestimonialSectionComponent extends React.Component {
  makeSlide = (fields, classes) =>
    fields.map((field, index) => {
      return (
        <Grid
          key={index}
          className={classes.grid}
          spacing={2}
          container
          direction='column'
          alignItems='center'
        >
          <Grid className={classes.gridItem} item xs={12}>
            {field.valued_partners?.image &&
              field.valued_partners &&
              field.valued_partners.image && (
                <img
                  src={field.valued_partners.image.url}
                  alt={field.valued_partners.image.alt || ''}
                  className={classes.image}
                />
              )}
          </Grid>
          <Grid className={classes.gridItemName} item xs={12}>
            <div className={classes.name}>
              {RichText.asText(field.valued_partners?.name_and_country)}
            </div>
          </Grid>
        </Grid>
      )
    })

  render() {
    const { primary, fields, classes } = this.props

    return (
      <div className={classes.outer}>
        <Container>
          <Title
            className={classes.title}
            mainTitle={primary.title}
            subTitle={primary.subtitle}
          />
        </Container>
        <div className={classes.slider}>
          <CarouselComponent
            slidesPerPage={5}
            slidesPerScroll={2}
            infinite
            breakpoints={{
              1000: {
                // these props will be applied when screen width is less than 1000px
                slidesPerPage: 1,
                offset: 0,
                clickToChange: false,
                infinite: true,
                centered: true,
              },
              500: {
                slidesPerPage: 1,
                slidesPerScroll: 1,
                clickToChange: false,
                offset: 0,
                animationSpeed: 2000,
                infinite: true,
                centered: true,
              },
            }}
            animationSpeed={1500}
            autoPlay={3000}
            stopAutoPlayOnHover
            offset={100}
            itemWidth={300}
            clickToChange={false}
            centered
          >
            {this.makeSlide(fields, classes)}
          </CarouselComponent>
        </div>
        <div className={classes.buttonHolder}>
          <Link
            href={`/index?slug=${primary.see_more_button_link._meta.uid}`}
            as={`/${primary.see_more_button_link._meta.uid}`}
            passHref
          >
            <Button className={classes.button} variant='outlined' component='a'>
              {RichText.asText(primary.see_more_button_text)}
            </Button>
          </Link>
        </div>
      </div>
    )
  }
}

TestimonialSectionComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(TestimonialSectionComponent)
