import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment TitleText on PageBodyTitle_with_text{
    primary{
      title
      content
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyTitle_with_text',
  fragmentName: 'TitleText',
}
