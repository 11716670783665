import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'

function MidPageContent(props) {
  const { classes, primary } = props

  const backgroundImage = primary.back_ground_image
    ? primary.back_ground_image.url
    : null

  const divStyle = {
    backgroundImage: 'url(' + backgroundImage + ')',
  }

  return (
    <div className={classes.outer} style={divStyle}>
      <Grid
        container
        className={classes.fullHeight}
        alignItems='center'
        justify='center'
      >
        <Grid item className={classes.titleOuter}>
          <Container>
            <h2 className={classes.quoteText}>
              {RichText.asText(primary.text)}
            </h2>
            <div className={classes.quoteAuthor}>
              {RichText.asText(primary.quote_author)}
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

MidPageContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MidPageContent)
