import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'

function TitleText(props) {
  const { classes, fields, primary } = props

  return (
    <div className={classes.outer}>
      <Container>
        {primary.title && (
          <h1 className={classes.title}>{RichText.asText(primary.title)}</h1>
        )}
        <div className={classes.content}>{RichText.render(primary.text)}</div>
      </Container>
    </div>
  )
}

TitleText.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TitleText)
