import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment TestimonialSectionFragment  on PageBodyTestimonials_carousel {
            primary{
                title
                subtitle
                see_more_button_text
              see_more_button_link {
                ... on _Document {
                  _meta {
                    uid
                  }
                }
              }
                
            }
            fields{
            valued_partners {
            ... on Valued_partners {
              name_and_country
              image
              }
            }
          }
        }

`

export default {
  query: gql(compress(query)),
  name: 'PageBodyTestimonials_carousel',
  fragmentName: 'TestimonialSectionFragment',
}
