import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
  fragment Text on PageBodyText {
    type
    primary {
      rich_text
    }
  }
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyText',
  fragmentName: 'Text',
}
