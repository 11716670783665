import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style.js'
import { RichText } from 'lib/prismic'
import { Grid, Container } from '@material-ui/core'
import Lightbox from 'lightbox-react'
import Button from '@material-ui/core/Button'
import { useLayout } from 'lib/layoutContext'

const Gallery = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const layout = useLayout()

  const makeSlide = (fields, classes) =>
    fields.map((field, index) => {
      const { max } = props

      return (
        <Grid item key={index} xs={6} sm={4} md={max ? max : 3}>
          <Button
            onClick={() => setIsOpen(true, setPhotoIndex(index))}
            aria-label={layout.main_enlarge_image_label}
          >
            <img
              className={classes.image}
              src={field.picture.small.url}
              alt={field.picture.small.alt || ''}
            />
          </Button>
        </Grid>
      )
    })

  const { classes, settings, fields, primary } = props

  return (
    <div className={classes.outer}>
      <Container className={classes.container}>
        {primary.title && (
          <h2 className={classes.title}>{RichText.asText(primary.title)}</h2>
        )}
        {primary.caption && (
          <p className={classes.text}>{RichText.asText(primary.caption)}</p>
        )}
        <Grid container className={classes.slides} justifyContent='center'>
          {makeSlide(fields, classes)}
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={fields[photoIndex].picture.url}
            nextSrc={fields[(photoIndex + 1) % fields.length].picture.url}
            prevSrc={
              fields[(photoIndex + fields.length - 1) % fields.length].picture
                .url
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + fields.length - 1) % fields.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % fields.length)
            }
          />
        )}
      </Container>
    </div>
  )
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  primary: PropTypes.object.isRequired,
  max: PropTypes.number,
}

export default withStyles(style)(Gallery)
