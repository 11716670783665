import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment AllTestimonialsFragment on PageBodyAll_testimonials {
  type
  primary {
    title
  }
}
`

export default {
  query: gql(compress(query)),
  name: 'PageBodyAll_testimonials',
  fragmentName: 'AllTestimonialsFragment',
}
