import gql from 'graphql-tag'
import compress from 'graphql-query-compress'

const query = `
fragment Contact_Page on PageBodyContact_page {
  primary{
    left_title
    middle_title
    right_title
    contact_details
    left_section
    contact_button_text
    map_point
  }
  fields{
    social_icon
    social_media_link{
      ... on _ExternalLink{
        url
      }
    }
  }
}`

export default {
  query: gql(compress(query)),
  name: 'PageBodyContact_page',
  fragmentName: 'Contact_Page',
}
