import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Button } from '@material-ui/core'
import { Container, Grid } from '@material-ui/core'
import styles from './style'
import RichText from '../../../lib/prismic/richtext'
import ContactButton from '../../_ui/contact-button/button'
import Link from 'next/link'

const setHref = (primary) => {
  let href = ''
  if (primary.button_link) {
    switch (primary.button_link.__typename) {
      case 'Page': {
        href = `/${primary.button_link._meta.uid}`
        break
      }
      case 'Article': {
        href = `/article/${primary.button_link._meta.uid}`
        break
      }
      case 'Projects': {
        href = `/service/${primary.button_link._meta.uid}`
        break
      }
      case '_ExternalLink':
      case '_ImageLink':
      case '_FileLink': {
        break
      }
    }
  }
  return href
}

function MidPageContent(props) {
  const { classes, primary } = props
  const href = setHref(primary)

  return (
    <div
      className={classes.outer}
      style={{ backgroundColor: primary.background_color }}
    >
      <Grid
        container
        className={classes.fullHeight}
        alignItems='center'
        justify='center'
      >
        <Grid item className={classes.titleOuter}>
          <Container className={classes.container}>
            {primary.title && (
              <h2 className={classes.title}>
                {RichText.asText(primary.title)}
              </h2>
            )}
            {primary.text && (
              <div className={classes.text}>
                {RichText.asText(primary.text)}
              </div>
            )}

            {primary.button_type === 'Link' && primary.button_link && href && (
              <Link href={href} passHref>
                <Button component='a' variant='contained'>
                  {RichText.asText(primary.button_text)}
                </Button>
              </Link>
            )}
            {primary.button_type === 'Link' && primary.button_link && !href && (
              <Button
                component='a'
                href={primary.button_link.url}
                target='_blank'
                variant='contained'
              >
                {RichText.asText(primary.button_text)}
              </Button>
            )}

            {primary.button_type === 'Contact Us Popup' && (
              <ContactButton variant='contained'>
                {RichText.asText(primary.button_text)}
              </ContactButton>
            )}
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

MidPageContent.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MidPageContent)
