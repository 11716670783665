import SimpleForm from '../../simple-form'
import styles from './style'
import { Box, Grid, withStyles } from '@material-ui/core'

const ContactForm = (props) => {
  const { classes, primary } = props
  const bgi = { backgroundImage: `url(${primary.background_image.url})` }
  return (
    <>
      <div style={bgi} className={classes.outer}>
        <Grid
          container
          className={classes.fullHeight}
          alignItems='center'
          justify='center'
        >
          <Grid item className={classes.titleOuter}>
            <h2 className={classes.hero_title}>{primary.hero_title}</h2>
            <p className={classes.sub_title}>{primary.hero_subtitle}</p>
          </Grid>
        </Grid>
      </div>
      <Grid container className={classes.gridContainer}>
        <Grid xs={12} md={4} item className={classes.firstGridItem}>
          <Box className={classes.textContent}>
            {primary.title && (
              <h3 className={classes.title}>{primary.title}</h3>
            )}
            {primary.title && (
              <p className={classes.paragraph}>{primary.paragraph}</p>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.secondGridItem}>
          <SimpleForm />
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(ContactForm)
