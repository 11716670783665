const styles = (theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: 2,
    color: theme.palette.primary[500],
    fontSize: 19,
    marginTop: 0,
  },
  date: {
    color: theme.palette.secondary[500],
    fontSize: 14,
  },
  author: {
    marginTop: 0,
    marginBottom: 10,
  },
  authorImage: {
    width: 40,
    borderRadius: '50%',
    display: 'block',
  },
  authorName: {
    margin: 0,
    fontSize: 12,
  },
  cardContent: {
    padding: 20,
  },
})

export default styles
